import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    publishedContents: [{
      "post_date": "2021/1/30",
      "user_id": "abcd1111",
      "user_name": "test1",
      "title": "テストタイトル1",
      "comment": "テストコメント1",
      "approval_status": "2",
      "contents_kinds": "動画",
      "main_image_url": "",
      "video_id": "2f5fa5d771",
      "thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "user_name_status": "1",
      "title_status": "1",
      "comment_status": "1",
      "cta_status": "1",
      "cta": "https://www.google.com/",
      "thumb_status": "1",
      "main_thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "order": "1"
    }, {
      "post_date": "2021/1/30",
      "user_id": "abcd2222",
      "user_name": "test2",
      "title": "テストタイトル2",
      "comment": "テストコメント2",
      "approval_status": "2",
      "contents_kinds": "動画",
      "main_image_url": "",
      "video_id": "2f5fa5d771",
      "thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "user_name_status": "1",
      "title_status": "1",
      "comment_status": "1",
      "cta_status": "1",
      "cta": "https://www.google.com/",
      "thumb_status": "1",
      "main_thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "order": "2"
    }, {
      "post_date": "2021/1/30",
      "user_id": "abcd3333",
      "user_name": "test3",
      "title": "テストタイトル3",
      "comment": "テストコメント3",
      "approval_status": "2",
      "contents_kinds": "動画",
      "main_image_url": "",
      "video_id": "2f5fa5d771",
      "thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "user_name_status": "1",
      "title_status": "1",
      "comment_status": "1",
      "cta_status": "1",
      "cta": "https://www.google.com/",
      "thumb_status": "1",
      "main_thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "order": "4"
    }, {
      "post_date": "2021/1/30",
      "user_id": "abcd4444",
      "user_name": "test4",
      "title": "テストタイトル4",
      "comment": "テストコメント4",
      "approval_status": "2",
      "contents_kinds": "動画",
      "main_image_url": "",
      "video_id": "2f5fa5d771",
      "thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "user_name_status": "1",
      "title_status": "1",
      "comment_status": "1",
      "cta_status": "1",
      "cta": "https://www.google.com/",
      "thumb_status": "1",
      "main_thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "order": "4"
    }, {
      "post_date": "2021/1/30",
      "user_id": "abcd5555",
      "user_name": "test5",
      "title": "テストタイトル5",
      "comment": "テストコメント5",
      "approval_status": "2",
      "contents_kinds": "動画",
      "main_image_url": "",
      "video_id": "2f5fa5d771",
      "thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "user_name_status": "1",
      "title_status": "1",
      "comment_status": "1",
      "cta_status": "1",
      "cta": "https://www.google.com/",
      "thumb_status": "1",
      "main_thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "order": "5"
    },
    {
      "post_date": "2021/1/30",
      "user_id": "abcd5555",
      "user_name": "test5",
      "title": "テストタイトル5",
      "comment": "テストコメント5",
      "approval_status": "2",
      "contents_kinds": "動画",
      "main_image_url": "",
      "video_id": "2f5fa5d771",
      "thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "user_name_status": "1",
      "title_status": "1",
      "comment_status": "1",
      "cta_status": "1",
      "cta": "https://www.google.com/",
      "thumb_status": "1",
      "main_thumb_image_url": "https://firebasestorage.googleapis.com/v0/b/test-account-c32a3.appspot.com/o/%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E4%B8%80%E8%A6%A7%2F17e19dae95e1c0%2F17e3282a6052fa%2F%E7%94%BB%E5%83%8F%2Fhand1.jpg?alt=media&token=f7ba91c8-f7a8-4be4-9135-3e469f58c39d",
      "order": "5"
    }],//公開中コンテンツ
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
