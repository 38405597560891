<template>
    <Iframe/>
</template>

<script>
// @ is an alias to /src
import Iframe from "@/components/Iframe.vue";

export default {
  name: "Home",
  components: {
    Iframe,
  },
};
</script>
