var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"core"},[(_vm.protecter === '')?_c('div'):_vm._e(),(_vm.protecter === true)?_c('div',{staticClass:"protecter",attrs:{"id":"protecter"}},[_c('p',{staticClass:"protecter-txt"},[_vm._v(" 掲載サイトのドメインが異なるか,公開するユーザーが設定されていません。 ")])]):_vm._e(),(_vm.showSlide && _vm.protecter === false)?_c('slick',{ref:"slick",staticClass:"itemlist",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.userInfo),function(user,index){return _c('div',{key:user.user_id,staticClass:"itemlist_item",attrs:{"id":"itemlist_item","data-target":("#item0" + (index + 1))}},[(user.contents_kinds == '動画')?_c('div',{staticClass:"itemlist_item_wrap"},[(user.user_name_status == '1')?_c('p',{staticClass:"itemlist_item_name",class:{ loading_filter: _vm.loadingFilter }},[_vm._v(" "+_vm._s(user.user_name)+" ")]):_vm._e(),_c('div',{staticClass:"itemlist_item_play",class:{ loading_filter: _vm.loadingFilter }},[_c('img',{attrs:{"src":require("../assets/play.png")}})]),_c('div',{staticClass:"loadingIcon"},[(_vm.loading)?_c('vue-loading',{attrs:{"type":"spin","color":"#D4D4D4","size":{ width: '50px', height: '50px' }}}):_vm._e()],1),(_vm.alertText)?_c('div',{staticClass:"LoadingContents"},[_vm._v(" 一時的にアクセスが集中しています。時間をおいてから"),_c('br'),_vm._v("再度アクセスしてください。 ")]):_vm._e(),_c('img',{staticClass:"main_thumb",class:{ loading_filter: _vm.loadingFilter },attrs:{"src":user.main_thumb_image_url,"alt":""}}),_c('div',{staticClass:"itemlist_item_body",class:{ loading_filter: _vm.loadingFilter }},[(user.thumb_status == '1' && user.thumb_image_url != '')?_c('div',{staticClass:"itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),(user.comment_status == '1' || user.title_status == '1')?_c('div',{staticClass:"itemlist_item_txt"},[(
                user.thumb_status == '1' &&
                user.thumb_image_url != '' &&
                user.title_status == '1'
              )?_c('p',{staticClass:"title-list"},[_vm._v(" "+_vm._s(user.title)+" ")]):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '1' &&
                user.comment_status == '1'
              )?_c('p',{staticClass:"title-list"},[_vm._v(" "+_vm._s(user.title)+" ")]):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '1' &&
                user.comment_status == '0'
              )?_c('p',{staticClass:"title-list"},[_vm._v(" "+_vm._s(user.title)+" ")]):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '0' &&
                user.comment_status == '1'
              )?_c('p',{staticClass:"title-list"}):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '0' &&
                user.comment_status == '1'
              )?_c('p',{staticClass:"title-list"},[_vm._v(" {{}} ")]):_vm._e(),(
                user.thumb_status == '0' &&
                user.title_status == '1' &&
                user.comment_status == '1'
              )?_c('p',{staticClass:"title-list"},[_vm._v(" "+_vm._s(user.title))]):_vm._e(),(
                user.thumb_status == '0' &&
                user.title_status == '0' &&
                user.comment_status == '1'
              )?_c('p',{staticClass:"title-list"}):_vm._e(),(
                user.thumb_status == '0' &&
                user.title_status == '1' &&
                user.comment_status == '0'
              )?_c('p',{staticClass:"title-list"},[_vm._v(" "+_vm._s(user.title)),_c('br')]):_vm._e()]):_vm._e()])]):_vm._e(),(user.contents_kinds == '画像')?_c('div',{staticClass:"itemlist_item_wrap image_contents"},[(user.user_name_status == '1')?_c('p',{staticClass:"itemlist_item_name"},[_vm._v(" "+_vm._s(user.user_name)+" ")]):_vm._e(),_c('img',{attrs:{"src":user.main_image_url,"alt":""}}),_c('div',{staticClass:"itemlist_item_body"},[(user.thumb_status == '1' && user.thumb_image_url != '')?_c('div',{staticClass:"itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),(
              (user.thumb_status == '1' && user.thumb_image_url != '') ||
              user.title_status == '1'
            )?_c('div',{staticClass:"itemlist_item_txt"},[(
                user.thumb_status == '1' &&
                user.thumb_image_url != '' &&
                user.title_status == '1'
              )?_c('p',[_vm._v(" "+_vm._s(user.title)+" ")]):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '1' &&
                user.comment_status == '1'
              )?_c('p',[_vm._v(" "+_vm._s(user.title))]):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '1' &&
                user.comment_status == '0'
              )?_c('p',[_vm._v(" "+_vm._s(user.title)+" ")]):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '0' &&
                user.comment_status == '1'
              )?_c('p'):_vm._e(),(
                user.thumb_status == '1' &&
                user.thumb_image_url == '' &&
                user.title_status == '0' &&
                user.comment_status == '1'
              )?_c('p'):_vm._e(),(
                user.thumb_status == '0' &&
                user.title_status == '1' &&
                user.comment_status == '1'
              )?_c('p',[_vm._v(" "+_vm._s(user.title))]):_vm._e(),(
                user.thumb_status == '0' &&
                user.title_status == '0' &&
                user.comment_status == '1'
              )?_c('p'):_vm._e(),(
                user.thumb_status == '0' &&
                user.title_status == '1' &&
                user.comment_status == '0'
              )?_c('p',[_vm._v(" "+_vm._s(user.title)),_c('br')]):_vm._e()]):_vm._e()])]):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"overray"},[_c('div',{staticClass:"overray_wrap"},[_c('div',{staticClass:"overray_inner"},[_vm._m(0),_vm._m(1),_vm._l((_vm.userInfo),function(user,index){return _c('div',{key:index,staticClass:"overray_item",attrs:{"id":("item0" + (index + 1))}},[(user.contents_kinds == '動画')?_c('div',{staticClass:"overray_item_wrap"},[_c('div',{staticClass:"overray_item_time",class:{ loading_filter: _vm.loadingFilter }},[_c('span',{staticClass:"overray_item_time_bar",attrs:{"id":"overray_item_time_bar"}})]),(user.user_name_status == '1')?_c('p',{staticClass:"overray_item_name",class:{ loading_filter: _vm.loadingFilter }},[_vm._v(" "+_vm._s(user.user_name)+" ")]):_vm._e(),(user.user_name_status == '0')?_c('p',{staticClass:"overray_item_name",class:{ loading_filter: _vm.loadingFilter }}):_vm._e(),_vm._m(2,true),_c('div',{staticClass:"overray_item_play",class:{ loading_filter: _vm.loadingFilter }},[_c('img',{attrs:{"src":require("../assets/play.png")}})]),(_vm.alertText)?_c('div',{staticClass:"LoadingContents-overray"},[_vm._v(" 一時的にアクセスが集中しています。時間をおいてから"),_c('br'),_vm._v("再度アクセスしてください。 ")]):_vm._e(),_c('div',{staticClass:"overray_item_video",class:{ loading_filter: _vm.loadingFilter },staticStyle:{"width":"100%","margin":"0%","height":"100%","z-index":"1"},attrs:{"id":index}},[_c('iframe',{staticClass:"overray_item_video_iframe",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","z-index":"-10000000000"},attrs:{"src":'https://player.vimeo.com/video/' +
                  user.video_id +
                  '&controls=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=0&loop=1',"frameborder":"0","allow":"autoplay; fullscreen;","allowfullscreen":"","title":"hand1.mp4"}})]),_c('div',{staticClass:"itemlist_item_body_overray",class:{ loading_filter: _vm.loadingFilter }},[(user.thumb_status == '1' && user.thumb_image_url != '')?_c('div',{staticClass:"overray_itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),(
                  (user.thumb_status == '1' && user.thumb_image_url != '') ||
                  user.title_status == '1' ||
                  user.comment_status == '1'
                )?_c('div',{staticClass:"overray_itemlist_item_txt"},[(
                    user.title_status == '1' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])]),_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '1' && user.comment_status == '0'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '0'
                  )?_c('div'):_vm._e()]):_vm._e()]),_c('div',{staticClass:"overray_item_bottom",class:{ loading_filter: _vm.loadingFilter }},[(_vm.service_logo == '1')?_c('a',{staticClass:"overray_item_bottom_ico",attrs:{"href":"https://mindfree.jp/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/ico_mf.png"),"alt":"MindFree"}})]):_vm._e(),(_vm.service_logo == '0')?_c('a',{staticClass:"overray_item_bottom_ico"}):_vm._e(),(user.cta_status == '1' && user.cta != '')?_c('a',{staticClass:"overray_item_bottom_btn",on:{"click":function($event){return _vm.sendMessage(user.cta)}}},[_c('img',{attrs:{"src":require("../assets/btn.png"),"alt":"詳しくはこちら"}})]):_vm._e()])]):_vm._e(),(user.contents_kinds == '画像')?_c('div',{staticClass:"overray_item_wrap"},[(user.user_name_status == '1')?_c('p',{staticClass:"overray_item_name"},[_vm._v(" "+_vm._s(user.user_name)+" ")]):_vm._e(),(user.user_name_status == '0')?_c('p',{staticClass:"overray_item_name"}):_vm._e(),_vm._m(3,true),_c('img',{attrs:{"src":user.main_image_url,"alt":""}}),_c('div',{staticClass:"itemlist_item_body_overray"},[(user.thumb_status == '1' && user.thumb_image_url != '')?_c('div',{staticClass:"overray_itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),(
                  (user.thumb_status == '1' && user.thumb_image_url != '') ||
                  user.title_status == '1'
                )?_c('div',{staticClass:"overray_itemlist_item_txt"},[(
                    user.title_status == '1' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])]),_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '1' && user.comment_status == '0'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '0'
                  )?_c('div'):_vm._e()]):_vm._e()]),_c('div',{staticClass:"overray_item_bottom",attrs:{"id":"image"}},[(_vm.service_logo == '1')?_c('a',{staticClass:"overray_item_bottom_ico",attrs:{"href":"https://mindfree.jp/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/ico_mf.png"),"alt":"MindFree"}})]):_vm._e(),(_vm.service_logo == '0')?_c('a',{staticClass:"overray_item_bottom_ico"}):_vm._e(),(user.cta_status == '1' && user.cta != '')?_c('a',{staticClass:"overray_item_bottom_btn",on:{"click":function($event){return _vm.sendMessage(user.cta)}}},[_c('img',{attrs:{"src":require("../assets/btn.png"),"alt":"詳しくはこちら"}})]):_vm._e()])]):_vm._e()])})],2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_prev"},[_c('img',{attrs:{"id":"overray_prev","src":require("../assets/arrow_prev_l.jpg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_next"},[_c('img',{attrs:{"id":"overray_next","src":require("../assets/arrow_next_l.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_item_close"},[_c('img',{attrs:{"id":"close_icon","src":require("../assets/ico_close.png"),"alt":"close"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_item_close"},[_c('span',{staticClass:"batsu"})])}]

export { render, staticRenderFns }